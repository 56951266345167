import { baseUrl } from "./urlConfig";
import { useDispatch } from "react-redux";
import Axios, { AxiosAdapter, AxiosInstance } from "axios";
import { createBrowserHistory } from "history";
import Swal from "sweetalert2";
import { logout } from "../../store/slices/sessionSlice";
import { RouteComponentProps } from "react-router";
import { store } from "../../index";
import { message } from "antd";

export class Api {
  constructor(props, controller, withBearer = true) {
    // const dispatch = useDispatch();
    this.Props = props;
    var state = store.getState();
    this.headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    };

    if (withBearer && state && state.auth && state.auth.session) {
      this.headers["Authorization"] = `Bearer ${state.auth.session.token}`;
    }
    this.axios = Axios.create({
      baseURL: baseUrl + `/api/${controller}`,
      withCredentials: true,
      headers: this.headers,
    });

    this.axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        var status = error.response ? error.response.status : undefined;
        Swal.close();
        if (error.message == "Network Error") {
          message.error("Network Error : Unable to connect to server.");
          store.dispatch(logout());

          this.Props.history.push("/login");
        } else if (
          error.response ||
          (error.response && error.response.data == "Access Denied")
        ) {
          if (status == 403 || status == 405 || status == 401) {
            message.error(error.message);
            store.dispatch(logout());
            this.Props.history.push("/login");
          } else if (status == 400 && Object.keys(error).length > 0) {
            var firstKey = Object.keys(error.response.data)[0];
            message.error(error.message);
          } else if (status == 500) {
            message.error(error.message);
          } else {
            message.error(error.message);
            this.Props.history.push("/login");
          }
        }

        return Promise.reject(error);
      }
    );
  }

  get(path) {
    return this.axios.get(path);
  }

  post(path, data) {
    return this.axios.post(path, data);
  }

  put(path, data) {
    return this.axios.put(path, data);
  }

  delete(path) {
    return this.axios.delete(path);
  }

  postFormData(path, data) {
    return this.axios.post(path, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
}
