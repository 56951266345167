import React, { lazy } from "react";

const configuration = lazy(() =>
  import("./configurations/configurations").then(({ Configuration }) => ({
    default: Configuration,
  }))
);
const attendance_rule_list = lazy(() =>
  import(
    "./attendance_configuration/attendance_rule/attendance_rule_list/attendance_rule_list"
  ).then(({ AttendanceRuleList }) => ({ default: AttendanceRuleList }))
);
const attendance_rule_detail = lazy(() =>
  import(
    "./attendance_configuration/attendance_rule/attendance_rule_detail/attendance_rule_detail"
  ).then(({ AttendanceRuleDetails }) => ({ default: AttendanceRuleDetails }))
);
const machine_list = lazy(() =>
  import("./machine/machine_list/machine_list").then(({ MachineList }) => ({
    default: MachineList,
  }))
);
const machine_view = lazy(() =>
  import("./machine/machine_view/machien_view").then(({ MachineView }) => ({
    default: MachineView,
  }))
);
const calendar = lazy(() =>
  import("./calendar/calendar").then(({ Calendar }) => ({ default: Calendar }))
);
const profession = lazy(() =>
  import("./professions/profession_list").then(({ ProfessionList }) => ({
    default: ProfessionList,
  }))
);
const role = lazy(() =>
  import("./roles/role_list").then(({ RoleList }) => ({ default: RoleList }))
);
export const configurationRoutes = [
  {
    path: "/configuration",
    exact: true,
    name: "Configurations",
    component: configuration,
  },
  {
    path: "/configuration/attendance-rule",
    exact: true,
    name: "Attendance Rule Configuration",
    component: attendance_rule_list,
  },
  {
    path: "/configuration/attendance-rule/detail/:id",
    exact: true,
    name: "Attendance Rule Detail",
    component: attendance_rule_detail,
  },
  {
    path: "/configuration/machine",
    exact: true,
    name: "Machine List",
    component: machine_list,
  },
  {
    path: "/configuration/machine/:id",
    name: "Machine View",
    component: machine_view,
  },
  { path: "/configuration/calendar", name: "Calendar", component: calendar },
  {
    path: `/configuration/profession`,
    name: "Profession",
    component: profession,
  },
  { path: `/configuration/role`, name: "Role", component: role },
];
