import React from "react";
import {
  HashRouter,
  Route,
  Switch,
  Router,
  BrowserRouter,
} from "react-router-dom";
import { Login } from "../pages/login/login";
import { Dashboard } from "../layouts/dashboard/dashboard";

export function App(props) {
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/login" component={Login} {...props} />
        <Route path="/" component={Dashboard} {...props} />
      </Switch>
    </HashRouter>
  );
}
