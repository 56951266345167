import { Api } from "./api";

export class AuthService {
  constructor(props) {
    this.props = props;
    this.api = new Api(props, "authentication", true);
  }

  LoginWeb(data) {
    return this.api.post("/login", data);
    // return new Promise((resolve) => {
    //     resolve(data);
    // })
    // return { firstName : "mikiyas", lastName : "abraham", userName : "mikiab12", role : ["administrator"]};
  }

  register(data) {
    let path = `/register`;
    return this.api.post(path, data);
  }

  changePassword(userId, data) {
    let path = `/changePassword/${userId}`;
    return this.api.put(path, data);
  }

  updateUser(userId, data) {
    let path = `/update/${userId}`;
    return this.api.put(path, data);
  }

  updateUserStatus(userId, status) {
    let path = `/update/user/${userId}/status/${status}`;
    return this.api.put(path, {});
  }

  login(data) {
    let path = `/login`;
    return this.api.post(path, data);
  }

  getUser(username) {
    let path = `/user/${username}`;
    return this.api.get(path);
  }
}
