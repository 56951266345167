import { lazy } from "react";

const ServiceDeliveryList = lazy(() =>
  import("./service-delivery-list/ServiceDeliveryList").then(
    ({ ServiceDeliveryList }) => ({ default: ServiceDeliveryList })
  )
);
const CreateServiceDelivery = lazy(() =>
  import("./create-service-delivery/create-service-delivery").then(
    ({ CreateServiceDelivery }) => ({ default: CreateServiceDelivery })
  )
);
const ServicePayment = lazy(() =>
  import("./service-payment/service-payment").then(({ ServicePayment }) => ({
    default: ServicePayment,
  }))
);
const ProfessionalServicePayment = lazy(() =>
  import("./service-payment/professional-service-payment").then(
    ({ ProfessionalServicePayment }) => ({
      default: ProfessionalServicePayment,
    })
  )
);
export const ServiceDeliveryRoutes = [
  {
    path: "/service-delivery",
    exact: true,
    name: "Service Delivery",
    component: ServiceDeliveryList,
  },
  {
    path: "/service-delivery/create",
    exact: true,
    name: "Create Service Delivery",
    component: CreateServiceDelivery,
  },
  {
    path: "/service-delivery/payment",
    exact: true,
    name: "Service Payment",
    component: ServicePayment,
  },
  {
    path: "/professional-service-delivery",
    exact: true,
    name: "Service Payment",
    component: ProfessionalServicePayment,
  },
];
