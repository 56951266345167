import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

const lookupSlice = createSlice({
  name: "lookup",
  initialState: {
    departments: null,
    attachments: null,
    batches: null,
    weeks: null,
    professionalTypes: null,
    shifts: null,
    roles: null,
    professions: null,
  },
  reducers: {
    setLookup(state, action) {
      const data = action.payload;
      state.departments = data.departments;
      state.attachments = data.attachments;
      state.batches = data.batches;
      state.weeks = data.weeks;
      state.professionalTypes = data.professionalTypes;
      state.shifts = data.shifts;
      state.roles = data.roles;
      state.professions = data.professions;
    },
    cleanLookup(state, action) {
      state.departments = null;
      state.attachments = null;
      state.batches = null;
      state.weeks = null;
      state.professionalTypes = null;
      state.shifts = null;
      state.roles = null;
      state.professions = null;
    },
  },
});

export const { setLookup, cleanLookup } = lookupSlice.actions;
export const lookupReducer = lookupSlice.reducer;

// export const lookupSelect = createSelector("lookup");
