import { lazy } from "react";

const RequirementList = lazy(() =>
  import("./list/requirement_list").then(({ RequirementList }) => ({
    default: RequirementList,
  }))
);
const CreateRequirement = lazy(() =>
  import("./create/create_requirement").then(({ CreateRequirement }) => ({
    default: CreateRequirement,
  }))
);
const ViewRequirement = lazy(() =>
  import("./view/view_requirement").then(({ ViewRequirement }) => ({
    default: ViewRequirement,
  }))
);
export const requirementRoutes = [
  {
    path: "/requirement",
    exact: true,
    name: "RequirementList",
    component: RequirementList,
  },
  {
    path: "/requirement/create",
    exports: true,
    name: "CreateRequirement",
    component: CreateRequirement,
  },
  {
    path: "/requirement/view/:id",
    name: "ViewRequirement",
    component: ViewRequirement,
  },
];
