import { lazy } from "react";

const LeaveList = lazy(() =>
  import("./leave_list/leave_list").then(({ LeaveList }) => ({
    default: LeaveList,
  }))
);
const NewLeavesList = lazy(() =>
  import("./leave_list/newly_approved_leaves").then(
    ({ NewlyApprovedLeaves }) => ({ default: NewlyApprovedLeaves })
  )
);
const AddLeave = lazy(() =>
  import("./add_leave/add_leave").then(({ AddLeave }) => ({
    default: AddLeave,
  }))
);
const RequestLeave = lazy(() =>
  import("./employee_leave_request/employee_leave_request").then(
    ({ EmployeeLeaveRequest }) => ({ default: EmployeeLeaveRequest })
  )
);
const EmployeeLeaveRequestForm = lazy(() =>
  import("./employee_leave_request/employe_leave_request_form").then(
    ({ EmployeeLeaveRequestForm }) => ({
      default: EmployeeLeaveRequestForm,
    })
  )
);
const PendingLeaves = lazy(() =>
  import("./pending_leaves/pending_leaves").then(({ PendingLeaves }) => ({
    default: PendingLeaves,
  }))
);
const LeaveHistory = lazy(() =>
  import("./leave_history/leave_history").then(({ LeaveHistory }) => ({
    default: LeaveHistory,
  }))
);
export const leaveRoutes = [
  { path: `/leave`, exact: true, name: "LeaveList", component: LeaveList },
  {
    path: `/leave/new`,
    exact: true,
    name: "NewLeaveList",
    component: NewLeavesList,
  },
  { path: `/leave/add`, exact: true, name: "AddLeave", component: AddLeave },
  {
    path: "/leave/employee/request",
    exact: true,
    name: "RequestLeave",
    component: RequestLeave,
  },
  {
    path: "/leave/pending",
    exact: true,
    name: "PendingLeave",
    component: PendingLeaves,
  },
  {
    path: "/leave/history",
    exact: true,
    name: "LeaveHistory",
    component: LeaveHistory,
  },
  {
    path: "/leave/employee/request-form",
    exact: true,
    name: "LeaveRequestForm",
    component: EmployeeLeaveRequestForm,
  },
];
