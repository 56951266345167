import { lazy } from "react";

const ScheduleList = lazy(() =>
  import("./list/schedule_list").then(({ ScheduleList }) => ({
    default: ScheduleList,
  }))
);
const ViewSchedule = lazy(() =>
  import("./view/view_schedule").then(({ ViewSchedule }) => ({
    default: ViewSchedule,
  }))
);
const MySchedule = lazy(() =>
  import("./my-schedule/my-schedule").then(({ MySchedule }) => ({
    default: MySchedule,
  }))
);
const ChangeRequest = lazy(() =>
  import("./my-schedule/change_request/change_request").then(
    ({ ChangeRequest }) => ({ default: ChangeRequest })
  )
);

export const scheduleRoutes = [
  {
    path: `/schedule`,
    exact: true,
    name: "ScheduleList",
    component: ScheduleList,
  },
  { path: `/schedule/view/:id`, name: "ViewSchedule", component: ViewSchedule },
  {
    path: "/schedule/my-schedule/:id",
    name: "MySchedule",
    component: MySchedule,
  },
  {
    path: "/schedule/change-request",
    name: "ChangeRequest",
    component: ChangeRequest,
  },
];
