import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logout, userSession } from "../../store/slices/sessionSlice";

import { Suspense } from "react";
import "./dashboard.css";
import { Button, Drawer, Form, Spin } from "antd";
import "antd/dist/antd.css";
//import Login from '../modules/shared/login/login'
import { Layout, Menu, Breadcrumb } from "antd";
import { Link, Switch, Route } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { routes } from "../../routes/routes";
import { FormComponentProps } from "antd/lib/form";
import { getNavigation, navigation } from "../navigation";
import Icon from "@ant-design/icons";
// import { Icon } from '@ant-design/compatible';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DashboardOutlined,
  FileOutlined,
  PlayCircleOutlined,
  BookOutlined,
  ReadOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { useSessionSelector } from "../../store/selectors";
import "./dashboard.css";
import { LookupService } from "../../services/api/lookup.service";
import { setLookup } from "../../store/slices/lookupSlice";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;

function Dashboard(props) {
  const [collapsed, setCollapsed] = useState(false);
  const [pathname, setPathname] = useState("/");
  const dispatch = useDispatch();
  const { sessionState } = useSessionSelector();
  const [loaded, setLoaded] = useState(false);

  const auth = useSelector((state) => state.auth);
  const lookupApi = new LookupService(props);

  useEffect(() => {
    if (!auth.authenticated || auth.session == null) {
      props.history.push("/login");
      dispatch(logout());
    } else {
      setLoaded(true);
      lookupApi.getAll().then((lookupData) => {
        dispatch(setLookup(lookupData.data));
      });
    }
  }, []);

  useEffect(() => {
    setPathname(props.location.pathname);
  }, [props.location.pathname]);

  function Logout() {
    dispatch(logout());
    props.history.push("/login");
  }

  function toggle() {
    setCollapsed(!collapsed);
  }

  function loading() {
    return (
      <div
        className="animated fadeIn pt-1 text-center"
        style={{ fontSize: 60 }}
      >
        Loading...
      </div>
    );
  }
  const { sm } = useBreakpoint();
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        breakpoint="lg"
        collapsedWidth={0}
        collapsible
        collapsed={collapsed}
        onBreakpoint={(broken) => {
          setCollapsed(broken);
        }}
        onCollapse={(broken, type) => {
          setCollapsed(broken);
        }}
        trigger={null}
        style={{
          zIndex: 1,
          position: "fixed",
          minHeight: "100vh",
        }}
      >
        <div className="logo">
          <img src="../logo.png" className="logo-image" />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[`/${pathname.split("/")[1]}`, pathname]}
          //  defaultSelectedKeys={[pathname]}
        >
          {loaded &&
            auth != null &&
            auth["role"] != null &&
            navigation[auth["role"]].length > 0 &&
            navigation[auth["role"]].map((item) => {
              if (item.subMenu == false) {
                return (
                  <Menu.Item
                    key={item.path}
                    icon={item.icon}
                    onClick={() => props.history.push(item.path)}
                  >
                    <Link to={item.path} onClick={sm ? "" : toggle}>
                      <span style={{ color: "white" }}>{item.title}</span>
                    </Link>
                  </Menu.Item>
                );
              } else if (item.subMenu == true) {
                return (
                  <SubMenu key={item.path} icon={item.icon} title={item.title}>
                    {item.children.map((subMenu) => (
                      <Menu.Item
                        key={subMenu.path}
                        icon={subMenu.icon}
                        onClick={() => props.history.push(subMenu.path)}
                      >
                        <Link to={subMenu.path} onClick={sm ? "" : toggle}>
                          <span style={{ color: "white" }}>
                            {subMenu.title}
                          </span>
                        </Link>
                      </Menu.Item>
                    ))}
                  </SubMenu>
                );
              }
            })}
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          <Menu
            theme="light"
            mode="horizontal"
            style={{
              lineHeight: "64px",
              flex: 1,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {collapsed ? (
                <MenuUnfoldOutlined
                  style={{ marginLeft: "10px" }}
                  onClick={toggle}
                />
              ) : (
                <MenuFoldOutlined
                  style={{ marginLeft: "220px" }}
                  onClick={toggle}
                />
              )}
              {/* <Icon
                className="trigger"
                type={collapsed ? 'menu-unfold' : 'menu-fold'}
                onClick={toggle}
              /> */}
              <Menu.Item
                key="1"
                className="logout"
                onClick={Logout}
                style={{ marginRight: "20px" }}
              >
                Logout
              </Menu.Item>
            </div>
          </Menu>
        </Header>
        <Content
          style={{
            margin: "50px 50px",
            width: sm ? "75%" : "95%",
            alignSelf: "center",
            marginLeft: !collapsed ? "200px" : "50px",
          }}
        >
          {/* <Spin
                          spinning={false}
                          > */}

          {/* <Container fluid>    */}
          <Suspense fallback={loading}>
            <Switch>
              {routes.map((route, idx) => {
                return (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(p) => <route.component {...p} {...props} />}
                  ></Route>
                );
              })}
            </Switch>
            {loading}
          </Suspense>
          {/* </Container> */}
          {/* </Spin> */}
        </Content>
        <Footer style={{ textAlign: "center" }}>
          MPSS ©2022 Created by MEDKET
        </Footer>
      </Layout>
    </Layout>
  );
}

// const DB = Form.create()(Dashboard);
export { Dashboard };
