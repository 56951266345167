import { useSelector } from "react-redux";
import React from "react";

export function useCustomSelectors() {
  const lookupState = useSelector((state) => state.lookup);

  return { lookupState };
}

export function useSessionSelector() {
  const sessionState = useSelector((state) => state.auth);
  return { sessionState };
}
