import { lazy } from "react";

const RotationList = lazy(() =>
  import("./rotation_list/rotation_list").then(({ RotationList }) => ({
    default: RotationList,
  }))
);
const RotationView = lazy(() =>
  import("./rotation_view/rotation_view").then(({ RotationView }) => ({
    default: RotationView,
  }))
);

export const rotationRoutes = [
  {
    path: "/rotation",
    exact: true,
    name: "RotationList",
    component: RotationList,
  },
  {
    path: "/rotation/:id",
    exact: true,
    name: "RotationView",
    component: RotationView,
  },
];
