import { lazy } from "react";

const ViewAttachment = lazy(() =>
  import("./attachment_view/attachment_view").then(({ ViewAttachment }) => ({
    default: ViewAttachment,
  }))
);

export const attachmentRoutes = [
  {
    path: "/attachment/:id",
    name: "ViewAttachment",
    component: ViewAttachment,
  },
];
