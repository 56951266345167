import React, {lazy} from "react";

const DepartmentList = lazy(() =>
    import("./department_list/department_list").then(({DepartmentList}) => ({
        default: DepartmentList,
    }))
);
const DepartmentView = lazy(() =>
    import("./department_view/department_view").then(({DepartmentView}) => ({
        default: DepartmentView,
    }))
);
const CaseTeamView = lazy(() =>
    import("./department_view/caseteam_view").then(({CaseTeamView}) => ({
        default: CaseTeamView,
    }))
);

const ProfessionalList = lazy(() =>
    import("./department_view/professional_list").then(({ProfessionalList}) => ({
        default: ProfessionalList,
    }))
);

export const departmentRoutes = [
    {
        path: "/department",
        exact: true,
        name: "Departments",
        component: DepartmentList,
    },
    {
        path: "/department/professionals",
        name: "Department Professionals",
        component: ProfessionalList
    },
    {
        path: "/department/:depId/caseTeam/:caseTeamId",
        name: "View Case Team",
        component: CaseTeamView,
    },
    {
        path: "/department/:id",
        name: "View Department",
        component: DepartmentView,
    }
];
