import { lazy } from "react";

const ProfessionalList = lazy(() =>
  import("./list/professional_list").then(({ ProfessionalList }) => ({
    default: ProfessionalList,
  }))
);
const ProfessionalView = lazy(() =>
  import("./view/professional_view").then(({ ProfessionalView }) => ({
    default: ProfessionalView,
  }))
);
const RegisterProfessional = lazy(() =>
  import("./register/professional_register").then(
    ({ RegisterProfessional }) => ({ default: RegisterProfessional })
  )
);
const EditProfessional = lazy(() =>
  import("./edit/professional_edit").then(({ ProfessionalEdit }) => ({
    default: ProfessionalEdit,
  }))
);
const ChangePassword = lazy(() =>
  import("./change-password/change_password").then(({ ChangePassword }) => ({
    default: ChangePassword,
  }))
);
export const professionalRoutes = [
  {
    path: "/professional",
    exact: true,
    name: "ProfessionalList",
    component: ProfessionalList,
  },
  {
    path: "/professional/register",
    name: "RegisterProfessionaL",
    component: RegisterProfessional,
  },
  {
    path: "/professional/view/:id",
    exact: true,
    name: "ProfessionalView",
    component: ProfessionalView,
  },
  {
    path: "/professional/edit/:id",
    exact: true,
    name: "ProfessionalEdit",
    component: EditProfessional,
  },
  {
    path: `/professional/change-password`,
    exact: true,
    name: "ChangePassword",
    component: ChangePassword,
  },
];
