export const Roles = {
  EMPLOYEE: "EMPLOYEE",
  ADMINISTRATOR: "ADMINISTRATOR",
  CASE_TEAM_LEADER: "CASE_TEAM_LEADER",
  DEPARTMENT_HEAD: "DEPARTMENT_HEAD",
  HR_MANAGER: "HR_MANAGER",
  HR_OFFICER: "HR_OFFICER",
  CEO: "CEO",
  DEPUTY_CEO: "DEPUTY_CEO",
  MEDICAL_DIRECTOR: "MEDICAL_DIRECTOR",
};
