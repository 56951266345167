import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { App } from "./app/app";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { createBrowserHistory, BrowserHistoryBuildOptions } from "history";
import { configureStore } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { Router } from "react-router";
import $ from "jquery";

window.$ = $;
const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const browHistory = {
  basename: baseUrl || undefined,
};

const history = createBrowserHistory(browHistory);

const initialState = window.initialReduxState;
export const { store, persistor } = configureStore(history);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <App />
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//     </PersistGate>
// </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
