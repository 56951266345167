import { lazy } from "react";

const Profile = lazy(() =>
  import("./profile").then(({ Profile }) => ({
    default: Profile,
  }))
);

export const profileRoutes = [
  {
    path: "/profile",
    exact: true,
    name: "Profile",
    component: Profile,
  },
];
