import { lazy } from "react";

const OffDayList = lazy(() =>
  import("./off_day_list/off_day_list").then(({ OffDayList }) => ({
    default: OffDayList,
  }))
);
export const offDayRoutes = [
  { path: `/off-day`, exact: true, name: "OffDayList", component: OffDayList },
];
