import React from "react";
import {
  DashboardOutlined,
  FileOutlined,
  UserOutlined,
  ApartmentOutlined,
  FieldTimeOutlined,
  ColumnWidthOutlined,
  CalculatorFilled,
} from "@ant-design/icons";

import { Roles } from "../data/roles";

const professional_dashboard = {
  path: "/professional-home",
  icon: <DashboardOutlined />,
  title: "Dashboard",
  subMenu: false,
};

const dashboard = {
  path: "/",
  icon: <DashboardOutlined />,
  title: "Dashboard",
  subMenu: false,
};

const department = {
  path: "/department",
  icon: <ApartmentOutlined />,
  title: "Departments",
  subMenu: false,
};

const professional = {
  path: "/professional",
  icon: <UserOutlined />,
  title: "Professional",
  subMenu: false,
};

const attendance = {
  path: "/attendance",
  icon: <FileOutlined />,
  title: "Attendance",
  subMenu: false,
};

const leave = {
  path: "/leave",
  icon: <FileOutlined />,
  title: "Leave",
  subMenu: false,
};

const offDay = {
  path: "/off-day",
  icon: <FileOutlined />,
  title: "Off Day",
  subMenu: false,
};

const schedule = {
  path: "/schedule",
  icon: <FileOutlined />,
  title: "Schedule",
  subMenu: false,
};

const payroll = {
  path: "/payroll",
  icon: <FileOutlined />,
  title: "Payroll",
  subMenu: false,
};

const configuration = (subMenu) => {
  return {
    path: "/configuration",
    icon: <CalculatorFilled />,
    title: "Configurations",
    subMenu: true,
    children: subMenu,
  };
};

const configuration_attendancerule = {
  path: "/configuration/attendance-rule",
  icon: <FileOutlined />,
  title: "Attendance Rule",
  subMenu: false,
};

const configuration_machine = {
  path: "/configuration/machine",
  icon: <FileOutlined />,
  title: "FP Machine",
  subMenu: false,
};

const configuration_calendar = {
  path: "/configuration/calendar",
  icon: <FileOutlined />,
  title: "Calendar",
  subMenu: false,
};

const configuration_profession = {
  path: "/configuration/profession",
  icon: <FileOutlined />,
  title: "Profession",
  subMenu: false,
};

const configuration_role = {
  path: "/configuration/role",
  icon: <FileOutlined />,
  title: "Role",
  subMenu: false,
};

const leave_history = {
  path: "/leave/history",
  icon: <ColumnWidthOutlined />,
  title: "My Leave History",
  subMenu: false,
};

const leave_pending = {
  path: "/leave/pending",
  icon: <ColumnWidthOutlined />,
  title: "Leaves",
  subMenu: false,
};

const profile = {
  path: "/profile",
  icon: <UserOutlined />,
  title: "Profile",
  subMenu: false,
};

const rotation = {
  path: "/rotation_workflow",
  icon: <UserOutlined />,
  title: "Rotation",
  subMenu: false,
};

const ADMINISTRATOR_NAVIGATION = [
  dashboard,
  department,
  professional,
  attendance,
  leave,
  offDay,
  rotation,
  configuration([
    configuration_attendancerule,
    configuration_machine,
    configuration_calendar,
    configuration_profession,
    configuration_role,
  ]),
];

const HR_OFFICER_NAVIGATION = [
  dashboard,
  professional,
  leave,
  offDay,
  attendance,
  configuration([
    configuration_machine,
    configuration_calendar,
    configuration_role,
  ]),
];

const CASE_TEAM_LEADER_NAVIGATINO = [
  dashboard,
  attendance,
  leave_pending,
  schedule,
  payroll,
];

const HR_MANAGER_NAVIGATION = [
  dashboard,
  department,
  professional,
  attendance,
  leave_pending,
  offDay,
  configuration([
    configuration_attendancerule,
    configuration_machine,
    configuration_calendar,
    configuration_profession,
    configuration_role,
  ]),
];

const PROFESSIONAL_NAVIGATION = [
  dashboard,
  profile,
  leave_history,
  attendance,
  schedule,
  payroll,
];

const DEPARTMENT_HEAD_NAVIGATION = [
  dashboard,
  attendance,
  leave_pending,
  schedule,
  payroll,
];

export const navigation = {
  [Roles.EMPLOYEE]: PROFESSIONAL_NAVIGATION,
  [Roles.ADMINISTRATOR]: ADMINISTRATOR_NAVIGATION,
  [Roles.HR_OFFICER]: ADMINISTRATOR_NAVIGATION,
  [Roles.HR_MANAGER]: HR_MANAGER_NAVIGATION,
  [Roles.CASE_TEAM_LEADER]: CASE_TEAM_LEADER_NAVIGATINO,
  [Roles.DEPARTMENT_HEAD]: DEPARTMENT_HEAD_NAVIGATION,
  [Roles.CEO]: DEPARTMENT_HEAD_NAVIGATION,
  [Roles.DEPUTY_CEO]: DEPARTMENT_HEAD_NAVIGATION,
  [Roles.MEDICAL_DIRECTOR]: DEPARTMENT_HEAD_NAVIGATION,
};
