import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const sessionSlice = createSlice({
  name: "session",
  initialState: {
    authenticated: false,
    session: null,
    role: "",
  },
  reducers: {
    login(state, action) {
      const session = action.payload;
      state.authenticated = true;
      state.session = session;
    },
    logout(state, action) {
      state.session = null;
      state.authenticated = false;
    },
    setRole(state, action) {
      const role = action.payload;
      state.role = role;
    },
    resetRole(state, action) {
      state.role = "";
    },
  },
});

export const { login, logout, setRole, resetRole } = sessionSlice.actions;
export const sessionReducer = sessionSlice.reducer;
