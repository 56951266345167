import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Checkbox,
  Card,
  Row,
  Col,
  Modal,
  Radio,
  Dropdown,
  Menu,
  message,
} from "antd";
import "./login.css";
import "antd/dist/antd.css";
import { Layout, Breadcrumb, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  login as li,
  userSession,
  setRole as setRoleState,
  resetRole as resetRoleState,
} from "../../store/slices/sessionSlice";
import { setLookup, cleanLookup } from "../../store/slices/lookupSlice";
import { AuthService } from "../../services/api/auth.service";
import Swal from "sweetalert2";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { LookupService } from "../../services/api/lookup.service";
import { navigation } from "../../layouts/navigation";
import { RoleService } from "../../services/api/role.service";

const FormItem = Form.Item;

export function Login(props) {
  const AuthApi = new AuthService(props);

  //  const confService = new ConfigService(props);;
  const dispatch = useDispatch();
  // const form = Form.useForm();
  const auth = useSelector((state) => state.auth);
  //const base = useSelector<authState, BaseData>( state => state.base );

  const [userRoles, setRoles] = useState([]);

  const [selectedRole, selectRole] = useState(null);

  const roleService = new RoleService(props);

  useEffect(() => {
    roleService.getAllRoles().then((res) => {
      setRoles(res.data);
    });
  }, []);

  const [sessionData, setSessionData] = useState(null);
  function handleSubmit(values) {
    Swal.showLoading();

    AuthApi.LoginWeb(values)
      .then((response) => {
        if (response.data["user"]["roles"].length == 1) {
          dispatch(li(response.data));
          Swal.close();
          dispatch(setRoleState(response.data["user"]["roles"][0]));
          let path = navigation[response.data["user"]["roles"][0]][0].path;
          props.history.push(path);
        } else {
          Swal.close();
          setSessionData(response.data);
          setRoleModal(true);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          message.error(
            "Invalid username or password. Please user the correct credentials."
          );
        }
      });
  }

  const [roleModal, setRoleModal] = useState(false);

  function setRole(role) {
    if (role != null) {
      dispatch(setRoleState(role));
      dispatch(li(sessionData));
      let path = navigation[role][0].path;
      props.history.push(path);
    }
  }

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  //const { getFieldDecorator } = props.form;
  return (
    <Row style={{ justifyContent: "center", marginTop: 50 }}>
      <Col xs={24} sm={12} md={6}>
        <Card
          className="login-form"
          title="Login"
          cover={<img src="../logo.png" className="card-avatar" />}
        >
          <Form onFinish={handleSubmit}>
            <FormItem
              name="username"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input
                prefix={<UserOutlined style={{ fontSize: 13 }} />}
                placeholder="Username"
              />
            </FormItem>

            <FormItem
              name="password"
              rules={[
                { required: true, message: "Please input your Password!" },
              ]}
            >
              <Input
                prefix={<LockOutlined type="lock" style={{ fontSize: 13 }} />}
                type="password"
                placeholder="Password"
              />
            </FormItem>

            <FormItem>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Log in
              </Button>
            </FormItem>
          </Form>
          {/* Create a modal */}
          <Modal
            title="Select Role"
            visible={roleModal}
            onOk={() => {
              setRole(selectedRole);
            }}
            onCancel={() => setRoleModal(false)}
          >
            <Radio.Group
              onChange={(e) => {
                selectRole(e.target.value);
              }}
              value={selectedRole}
            >
              {userRoles
                .filter((i) => sessionData?.user?.roles?.includes(i.code))
                .map((role) => (
                  <Radio style={radioStyle} value={role.code}>
                    {role.roleName}
                  </Radio>
                ))}
            </Radio.Group>
          </Modal>
        </Card>
      </Col>
    </Row>
  );
}

// const login = (Form.create())(Login);
