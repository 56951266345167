import { Api } from "./api";

export class RoleService {
  constructor(props) {
    this.props = props;
    this.api = new Api(props, "role");
  }

  getAllRoles() {
    var path = ``;
    return this.api.get(path);
  }

  createRole(data) {
    var path = ``;
    return this.api.post(path, data);
  }

  updateRole(data) {
    var path = ``;
    return this.api.put(path, data);
  }

  getRole(id) {
    let path = `/${id}`;
    return this.api.get(path);
  }

  deleteRole(id) {
    let path = `/${id}`;
    return this.api.delete(path);
  }
}
