import { lazy } from "react";

const RotationWorkflowView = lazy(() =>
  import("./rotation_workflow_view/RotationWorkflowView").then(
    ({ RotationWorkflowView }) => ({ default: RotationWorkflowView })
  )
);

var RotationWorkflowList = lazy(() =>
  import("./rotation_workflow_list/RotationWorkflowList").then(
    ({ RotationWorkflowList }) => ({ default: RotationWorkflowList })
  )
);

export const roationWorkflowRoutes = [
  {
    path: `/rotation_workflow`,
    exact: true,
    name: "RotationWorkflowList",
    component: RotationWorkflowList,
  },
  {
    path: `/rotation_workflow/:id`,
    exact: true,
    name: "RotationWorkflowView",
    component: RotationWorkflowView,
  },
];
