import React from "react";
import { attachmentRoutes } from "../pages/attachment/attachment_routes";
import { departmentRoutes } from "../pages/department/department_route";
import { professionalRoutes } from "../pages/professional/professional_route";
import { rotationRoutes } from "../pages/rotation/rotation_routes";
import { requirementRoutes } from "../pages/requirement/requirement_routes";
import { scheduleRoutes } from "../pages/schedule/schedule_routes";
import { changeRequestRoutes } from "../pages/change_requests/change_request_routes";
import { ServiceDeliveryRoutes } from "../pages/service/service_delivery_routes";
import { configurationRoutes } from "../pages/configuration/configuration_routes";
import { attendanceRoutes } from "../pages/attendance/attendance_routes";
import { leaveRoutes } from "../pages/leave/leave_routes";
import { offDayRoutes } from "../pages/off_day/off_day_routes";
import { profileRoutes } from "../pages/profile/profile_routes";
import { roationWorkflowRoutes } from "../pages/rotation_workflow/rotation_workflow_routes";

const Dashboard = React.lazy(() =>
  import("../pages/dashboard/index").then(({ Dashboard }) => ({
    default: Dashboard,
  }))
);

const Home = React.lazy(() =>
  import("../pages/home/home").then(({ Home }) => ({ default: Home }))
);
const ProfessionalHome = React.lazy(() =>
  import("../pages/home/professional_home").then(({ ProfessionalHome }) => ({
    default: ProfessionalHome,
  }))
);

export const routes = [
  // { path : '/login', exact : true, name : "Login", component : Login },
  { path: "/", exact: true, name: "Home", component: Dashboard },
  {
    path: "/professional-home",
    exact: true,
    name: "Professional Home",
    component: ProfessionalHome,
  },
  ...departmentRoutes,
  ...rotationRoutes,
  ...professionalRoutes,
  ...attachmentRoutes,
  ...requirementRoutes,
  ...scheduleRoutes,
  ...changeRequestRoutes,
  ...ServiceDeliveryRoutes,
  ...configurationRoutes,
  ...attendanceRoutes,
  ...leaveRoutes,
  ...offDayRoutes,
  ...profileRoutes,
  ...roationWorkflowRoutes,
];
