import React, { lazy } from "react";

const ChangeRequestView = lazy(() =>
  import("./view/change_request_view").then(({ ViewChangeRequest }) => ({
    default: ViewChangeRequest,
  }))
);
const ChangeRequestList = lazy(() =>
  import("./list/chagne_request_list").then(({ ChangeRequestList }) => ({
    default: ChangeRequestList,
  }))
);

export const changeRequestRoutes = [
  {
    path: "/change-request",
    exact: true,
    name: "ChangeRequestList",
    component: ChangeRequestList,
  },
  {
    path: "/change-request/:id",
    name: "ChangeRequestView",
    component: ChangeRequestView,
  },
];
