import React, { lazy } from "react";

var attendanceReport = lazy(() =>
  import("./attendance_report/attendance_report").then(
    ({ AttendanceReport }) => ({ default: AttendanceReport })
  )
);
var published_attendance = lazy(() =>
  import("./published_attendance/published_attendance_list").then(
    ({ PublishedAttendances }) => ({ default: PublishedAttendances })
  )
);
var published_attendance_view = lazy(() =>
  import("./published_attendance/publish_attendance_view").then(
    ({ PublishedAttendanceView }) => ({ default: PublishedAttendanceView })
  )
);
var bulk_update = lazy(() =>
  import("./published_attendance/bulk_update_attendance").then(
    ({ BulkUpdateAttendance }) => ({ default: BulkUpdateAttendance })
  )
);
var counter = lazy(() =>
  import("./published_attendance/status_counter").then(
    ({ AttendanceStatusCounter }) => ({ default: AttendanceStatusCounter })
  )
);

var employeeAttendance = lazy(() =>
  import("./published_attendance/employee_attendance").then(
    ({ EmployeeAttendance }) => ({ default: EmployeeAttendance })
  )
);

export const attendanceRoutes = [
  {
    path: `/attendance`,
    exact: true,
    name: "Published Attendance",
    component: published_attendance,
  },
  {
    path: `/attendance/view/:id`,
    exact: true,
    name: "Published Attendance View",
    component: published_attendance_view,
  },
  {
    path: "/attendance/generate",
    exact: true,
    name: "Attendance Report",
    component: attendanceReport,
  },
  {
    path: `/attendance/bulk-update/:id`,
    exact: true,
    name: "Bulk Update Attendance",
    component: bulk_update,
  },
  {
    path: `/attendance/status-counter/:id`,
    exact: true,
    name: "Attendance Status Counter",
    component: counter,
  },
  {
    path: `/attendance/employee/:id`,
    exact: true,
    name: "Employee-attendance",
    component: employeeAttendance,
  },
];
