import { combineReducers, createStore } from "redux";
import { sessionReducer } from "./slices/sessionSlice";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import { lookupReducer } from "./slices/lookupSlice";

export const rootReducer = combineReducers({
  auth: sessionReducer,
  lookup: lookupReducer,
});

export function configureStore(history) {
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === "development";
  if (
    isDevelopment &&
    typeof window !== "undefined" &&
    window.devToolsExtension
  ) {
    enhancers.push(window.devToolsExtension());
  }

  const persistConfig = {
    key: "mpss",
    storage,
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  let store = createStore(persistedReducer);
  let persistor = persistStore(store);

  return { store, persistor };
}
