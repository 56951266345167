import { Api } from "./api";

export class LookupService {
  constructor(props) {
    this.props = props;
    this.api = new Api(props, "lookup");
  }

  getDepartments() {
    let path = `/departments`;
    return this.api.get(path);
  }

  getAttachmetns() {
    let path = `/attachments`;
    return this.api.get(path);
  }

  getWeeks() {
    let path = `/weeks`;
    return this.api.get(path);
  }

  getBatches() {
    let path = `/batches`;
    return this.api.get(path);
  }

  getAll() {
    var path = `/all`;
    return this.api.get(path);
  }

  getAllShifts() {
    var path = `/shifts`;
    return this.api.get(path);
  }
}
